import * as types from "./types";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ALL_COMPANIES_RECEIVE:
      return {
        ...state,
        data: action.payload.data,
        paging: action.payload.paging,
      };
    default:
      return state;
  }
};
