import { axiosInstance } from "../../network/apis/index";

export const getAllIndustries = async (params) => {
  return await axiosInstance.get(
    `/candidate/companies/Industry/listWithPagination`,
    {
      params,
    }
  );
};
