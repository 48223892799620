import { blobToBase64 } from "../../../utils/Shared";
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", " User.ReadWrite"],
};

//endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};

export const graphUserPhotoData = async (accessToken) => {
  const options = createRequestHeaders(accessToken);
  return fetch(graphConfig.graphMePhotoEndpoint, options)
    .then((response) => {
      if (response.status === 404) {
        throw new Error('');
      } else {
        return response.blob();
      }
    })
    .then(async (e) => {
      const imageURL = await blobToBase64(e);
      return imageURL;
    })
    .catch((e) => console.error(e, "error-graph-photo"));
};
export const graphUserData = async (accessToken) => {
  const options = createRequestHeaders(accessToken);
  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((e) => console.error(e, "error-graph-user"));
};

export const createRequestHeaders = (token) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    encoding: null,
  });
  return {
    method: "GET",
    mode: "cors",
    headers: headers,
  };
};
