import {axiosInstance} from '../../network/apis';
let handlerEnabled = true;
const postContactUs = async (data) => {
  return await axiosInstance.post(
    "/candidate/home/ContactUs/SendingEmail",
    data,
    { handlerEnabled }
  );
};

const getContactUs = async () => {
  return await axiosInstance.get("/hrcomsite/pages/ContactUs", {
    handlerEnabled,
  });
};

export default {
  postContactUs,
  getContactUs,
};
