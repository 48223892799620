import * as types from "./types";

export const browseByIndustryRequest = (payload) => ({
  type: types.BROWSE_BY_INDUSTRY_REQUEST,
  payload
});

export const browseByIndustryReceive = payload => ({
  type: types.BROWSE_BY_INDUSTRY_RECEIVE,
  payload
});
