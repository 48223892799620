import { axiosInstance } from '../../network/apis';

const getTopCompanies = async (data) => {
  return await axiosInstance.get('/candidate/home/companies', {
    params: data,
    handlerEnabled: false,
  });
};
const allowsSharingCandidateData = async (data) => {
  return await axiosInstance.put('/candidate/profile/set_allow_share', {
    is_allowed_profile_share: data,
  });
};

export default {
  getTopCompanies,
  allowsSharingCandidateData,
};
