import { axiosInstance } from "../../network/apis/index";
import qs from 'qs';
let handlerEnabled = true;

const jobPreferenceRequest = async params => {
  return await axiosInstance.get(`/candidate/job_preferences`,
    {
      params, paramsSerializer: params => {
        return qs.stringify(params)
      }, handlerEnabled
    })
};



const deletejobPreference = async (id) => {
  return await axiosInstance.delete(
    `/candidate/job_preferences/${id}`,
    {
      handlerEnabled,
    }
  );
};

const addJobPreference = async (data) =>
  await axiosInstance.post(`/candidate/job_preferences`, data, {
    handlerEnabled,
  });


const getJobPreferenceRequest = async ({ id }) => {
  return await axiosInstance.get(
    `/candidate/job_preferences/${id}`,
    { handlerEnabled }
  );
};

const editJobPreference = async (data) => {
  return await axiosInstance.put(
    `/candidate/job_preferences/${data.id}`, data,
    { handlerEnabled }
  );
};

const addJobPreferenceByJobRequest = async (job_request_id) =>
  await axiosInstance.post(`/candidate/job_preferences/add_by_job_request/${job_request_id}`, {
    handlerEnabled,
  });

export default {
  jobPreferenceRequest,
  deletejobPreference,
  addJobPreference,
  getJobPreferenceRequest,
  editJobPreference,
  addJobPreferenceByJobRequest
};
