import * as types from "./types";

const INITIAL_STATE= {
    isModalOpen : false
}

export default (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case types.IS_MODAL_OPEN:
            return { ...state, isModalOpen : action.payload};
        default: return state;
    }
}