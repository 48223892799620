import React from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faSnapchat,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import messages from "../../assets/Local/messages";
import logo from "../../assets/images/0-02.png";

function Footer() {
  const lang = useSelector((state) => state.lang);
  const message = messages[lang];

  return (
    <div className={`footer_primary_bg py-5 `}>
      <div className="container py-5">
        <div className="row mb-4">
          <div className="mb-3 col-md-3 p-1">
            <img src={logo} alt="HRCom logo" className="footer-logo w-75 mt-3" />
          </div>
          <div className="mb-3 col-md-3">
            {/* <Link to="/products"> */}
              <h6 className="footer_titles my-3  bold_font">
                {message.footer.ourProducts}
              </h6>
            {/* </Link> */}

            <a href="https://hrcom.io/registration">
              <p className="semi_bold_font text-capitalize">
                {message.footer.startFreeTrial}
              </p>
            </a>
            <a href="https://hrcom.io/companyLogin">
              <p className="semi_bold_font text-capitalize">
                {message.footer.postFreeJob}
              </p>
            </a>
            <a href="https://jobseeker.hrcom.io/?locale=en">
              <p className="semi_bold_font text-capitalize">
                {message.footer.searchForJobs}
              </p>
            </a>
          </div>
          <div className="mb-3 col-md-3">
            <a href="https://hrcom.io/About-us">
              <h6 className="footer_titles my-3  bold_font">
                {message.footer.aboutUs}
              </h6>
            </a>
            <Link to="/privacyPolicy">
              <p className="semi_bold_font">{message.privacyPolicy}</p>
            </Link>
            <Link to="/termsAndConditions">
              <p className="semi_bold_font">{message.termsAndConditions}</p>
            </Link>
            <Link to="/disclaimer">
              <p className="semi_bold_font">{message.disclaimer}</p>
            </Link>
          </div>
          <div className="mb-3 col-md-3 px-4">
            <h6 className="footer_titles my-3  bold_font">
              {message.shared.address}
            </h6>
            <p className="mb-0  my-3 semi_bold_font">
              <span className="d-block">HRCom</span>
              {message.footer.address}
            </p>
          </div>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 col-lg-8 mb-4 mb-md-0">
            {lang === "ar" ? (
              <div className="gradient-line-ar rounded w-100"></div>
            ) : (
              <div className="gradient-line rounded w-100"></div>
            )}
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-center social-icons justify-content-lg-end justify-content-start">
            <a href="https://instagram.com/hrcom.io" target="_blank">
              <div className="socialMediaCircle rounded-circle d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </a>
            <a href="https://twitter.com/HRcomio" target="_blank">
              <div className="socialMediaCircle rounded-circle d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
            </a>
            <a href="https://www.snapchat.com/add/hrcom_io" target="_blank">
              <div className="socialMediaCircle rounded-circle d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faSnapchat} />
              </div>
            </a>
            <a
              href="https://www.facebook.com/HrcomRecruitment/"
              target="_blank"
            >
              <div className="socialMediaCircle rounded-circle d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faFacebookF} />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/hrcom-io" target="_blank">
              <div className="socialMediaCircle rounded-circle d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </div>
            </a>
            <a href="https://www.youtube.com/@hrcom5424" target="_blank">
              <div className="socialMediaCircle rounded-circle d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faYoutube} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
