import store from "../../store";
import { loader } from "../../store/Loader/LoaderAction";
import Cookies from "js-cookie";
import Auth from "../../utils/Auth";
import History from "../../routes/History";
import { getEnv } from "../../utils/Shared";

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    !request.url.includes("latest") &&
      document.body.classList.add("loading-indicator");
    !request.url.includes("latest") && store.dispatch(loader(true));
    const token = Cookies.get("token", { domain: getEnv() });
    request.headers["Accept-Language"] =
      localStorage.getItem("lang") === "ar" ? "ar-SA" : "en-US";
    request.headers["Content-Type"] = "application/json";
    if (request.url === "https://www.linkedin.com/oauth/v2/accessToken") {
      console.log("linkedin");
      // request.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    !response.request.responseURL.includes("latest") &&
      document.body.classList.remove("loading-indicator");
    !response.request.responseURL.includes("latest") &&
      store.dispatch(loader(false));
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    store.dispatch(loader(false));
    document.body.classList.remove("loading-indicator");
    if (error.response.status === 401) {
      Auth.signOut();
      // History.push('/auth/login');
    }
  }
  return Promise.reject({ ...error });
};
