import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {  doc,onSnapshot } from "firebase/firestore";
import store from "../store";
import {popupNotificationRequest} from "../store/Notification/actions";
import { firebaseConfig } from "./config";


firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();


export const realTimeDbUnreadNotifications = userId => {

 onSnapshot(doc(db, "notifications", userId), (doc) => {
  if(doc?.data()?.has_unseen_notifications){
    store.dispatch(popupNotificationRequest())
  }
});
};

