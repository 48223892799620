import * as types from "./types";

export const getJobsRequest = (payload) => ({
  type: types.GET_JOBS_REQUEST,
  payload,
});

export const getJobsReceive = (payload) => ({
  type: types.GET_JOBS_RECEIVE,
  payload,
});

export const getJobDetailsRequest = (payload) => ({
  type: types.GET_JOB_DETAILS_REQUEST,
  payload,
});
export const updateJobWithFavorite = (payload) => ({
  type: types.UPDATE_JOB_FAVORITE_REQUEST,
  payload,
});

export const updateJobWithFavoriteRecieve = (payload) => ({
  type: types.UPDATE_JOB_FAVORITE_RECIEVE,
  payload,
});
export const getJobDetailsReceive = (payload) => ({
  type: types.GET_JOB_DETAILS_RECEIVE,
  payload,
});

export const addRemoveFavoriteJobsRequuest = (payload) => ({
  type: types.ADD_REMOVE_FAVORITE_JOBS,
  payload,
});

export const getLatestJobsRequest = (payload) => ({
  type: types.GET_LATEST_JOBS_REQUEST,
  payload,
});
export const getLatestJobsRecieve = (payload) => ({
  type: types.GET_LATEST_JOBS_RECIEVE,
  payload,
});

export const addJobFilters = (payload) => ({
  type: types.ADD_JOB_FILTERS_REQUEST,
  payload,
});

export const getMyJobsAppliedRequest = (payload) => ({
  type: types.GET_MY_JOBS_APPLIED_REQUEST,
  payload,
});

export const getMyJobsAppliedReceive = (payload) => ({
  type: types.GET_MY_JOBS_APPLIED_RECEIVE,
  payload,
});

export const getMyJobsSavedRequest = (payload) => ({
  type: types.GET_MY_JOBS_SAVED_REQUEST,
  payload,
});

export const getMyJobsSavedReceive = (payload) => ({
  type: types.GET_MY_JOBS_SAVED_RECEIVE,
  payload,
});
export const shareJobRequest = (payload) => ({
  type: types.SHARE_JOB_REQUEST,
  payload,
});
export const shareJobRecieve = (payload) => ({
  type: types.SHARE_JOB_RECIEVE,
  payload,
});