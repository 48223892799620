import History from "../routes/History";

export const getUrl = () => {
  if (window.location.href.includes("stage.hrcom.site")) {
    return "-candidate.stage.hrcom.site";
  } else if (window.location.href.includes("hrcom.site")) {
    return ".candidate.hrcom.site";
  } else if (window.location.href.includes("hrcom.co")) {
    return ".candidates.hrcom.co";
  } else {
    return ".candidate.hrcom.io";
  }
};

export const handleNotificationRedirection = (
  redirectionId,
  item_id = null,
  domain = null
) => {
  switch (parseInt(redirectionId)) {
    case 1:
      window.open(
        `https://${domain}${getUrl()}/moreInformationQuestions?id=${item_id}`
      );
      break;
    case 2:
      window.open(`https://${domain}${getUrl()}/preoffer?id=${item_id}`);
      break;
    case 3:
      window.open(`https://${domain}${getUrl()}/offer?id=${item_id}`, "_blank");
      break;
    case 4:
      window.open(
        `https://${domain}${getUrl()}/moreInformationQuestions?id=${item_id}`
      );
      break;
    case 5:
      History.push("/profile");
      break;

    case 6:
      window.open(`https://${domain}${getUrl()}/interview/${item_id}`);
      break;
    case 7:
      window.open(`https://${domain}${getUrl()}/interview/${item_id}`);
      break;
    case 25:
      window.open(`https://${domain}${getUrl()}/contractor?id=${item_id}`, "_blank");
      break;
    case 30:
      History.push(`/jobs?job_post_id=${item_id}`)
      break;
  }
};
