import * as types from './types';

export const requestContactUsForm = (data) => {
    return {
      type: types.FETCH_CONTACT_US_FORM_REQUEST,
      payload : data
    }
};


export const recieveContactUsForm = (data) => {
  return {
    type: types.FETCH_CONTACT_US_FORM_RECEIVE,
    payload : data
  }
};

export const requestContactUsContent = () => {
  return {
    type: types.FETCH_CONTACT_US_REQUEST
  }
};


export const recieveContactUsContent = (data) => {
  return {
    type: types.FETCH_CONTACT_US_RECEIVE,
    payload : data
  }
};



  
