export const JOB_PREFERENCE_REQUSET = 'JOB_PREFERENCE_REQUSET';
export const JOB_PREFERENCE_RECIEVE = 'JOB_PREFERENCE_RECIEVE';

export const DELETE_JOB_PREFERENCE_REQUSET = "DELETE_JOB_PREFERENCE_REQUSET";
export const ADD_JOB_PREFERENCE_REQUEST = "ADD_JOB_PREFERENCE_REQUEST";
export const ADD_JOB_PREFERENCE_RECIEVE = "ADD_JOB_PREFERENCE_RECIEVE"

export const GET_JOB_PREFERENCE_REQUEST = "GET_JOB_PREFERENCE_REQUEST"
export const GET_JOB_PREFERENCE_RECIEVE = "GET_JOB_PREFERENCE_RECIEVE"

export const EDIT_JOB_PREFERENCE_REQUEST = "EDIT_JOB_PREFERENCE_REQUEST"
export const EDIT_JOB_PREFERENCE_RECIEVE = "EDIT_JOB_PREFERENCE_RECIEVE"

export const ADD_JOB_PREFERENCE_BY_JOB_REQUEST = "ADD_JOB_PREFERENCE_BY_JOB_REQUEST"
