import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "./../NameAvatar/NameAvatar";
import { useGoogleLogout } from "react-google-login";

import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Auth from "../../utils/Auth";
import Cookies from "js-cookie";
import History from "../../routes/History";
import { getEnv } from "../../utils/Shared";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

function DropDownMenu(props) {
  const googleLogout = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    jsSrc: "https://apis.google.com/js/api.js",
    onLogoutSuccess: () => console.log(""),
  });
  const lang = useSelector((state) => state.lang);
  const {
    intl: { messages },
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [userImg, setUserImage] = React.useState("");
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Cookies.get("token", { domain: getEnv() })) {
      setUserName(
        JSON.parse(Cookies.get("userData", { domain: getEnv() })).first_name
      );
      setUserImage(
        JSON.parse(Cookies.get("userData", { domain: getEnv() })).image_url
      );
    } else {
      History.push("/auth/login");
    }
  }, []);

  const userMenu = [
    {
      id: 1,
      name: messages.shared.profile,
      link: "/profile",
    },
    {
      id: 2,
      name: messages.shared.myJobs,
      link: "/myJobs",
    },
    {
      id: 3,
      name: messages.shared.logout,
      link: "/auth/login",
    },
  ];
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, linkId) => {
    if (linkId === 3) {
      googleLogout.signOut();
      Auth.signOut();
      localStorage.removeItem("job_post_id")
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const renderDropDownList = (userMenu) => {
    return userMenu.map((item) => {
      return (
        <a href={item.link} key={item.name}>
          <MenuItem
            className={
              lang === "ar"
                ? "text-right medium_font text-dark"
                : "text-left medium_font text-dark"
            }
            dir={lang === "ar" ? "rtl" : "ltr"}
            onClick={(event) => handleClose(event, item.id)}
          >
            {item.name}
          </MenuItem>
        </a>
      );
    });
  };

  return (
    <div className={classes.root}>
      <div style={{ zIndex: 999 }}>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Avatar name={userName} image={userImg} />
          <KeyboardArrowDownIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {renderDropDownList(userMenu)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

const DropDownMenuComponent = injectIntl(DropDownMenu);

export default DropDownMenuComponent;
