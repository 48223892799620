import * as type from "./types";

const INITIAL_STATE = {
  currentJob: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.JOB_PREFERENCE_RECIEVE:
      return {
        ...action.payload,
      };
    case type.GET_JOB_PREFERENCE_RECIEVE:
      return {
        ...state,
        currentJob: action.payload
      };
    default:
      return state;
  }
};
