import React from "react";

export const Home = React.lazy(() => import("../containers/Home/Home"));
export const Companies = React.lazy(() =>
  import("../containers/AllCompanies/AllCompanies")
);
export const NotFound = React.lazy(() =>
  import("../components/NotFound/NotFound")
);
export const AllJobs = React.lazy(() =>
  import("../containers/AllJobs/AllJobs")
);
export const PrivacyPolicy = React.lazy(() =>
  import("../components/PrivacyPolicy/PrivacyPolicy")
);
export const TermsAndConditions = React.lazy(() =>
  import("../components/TermsAndConditions/TermsAndConditions")
);
export const JobPreferences = React.lazy(() =>
  import("../containers/JobPreferences/JobPreferences")
);


export const AddEditJobPreference = React.lazy(() =>
  import('../containers/JobPreferences/AddEditJobPreference')
);
export const Disclaimer = React.lazy(() =>
  import("../components/Disclaimer/Disclaimer")
);
export const AllCities = React.lazy(() =>
  import("../containers/AllCities/AllCities")
);
export const AllIndustries = React.lazy(() =>
  import("../containers/AllIndustries/AllIndustries")
);
export const Signup = React.lazy(() =>
  import("../containers/Auth/Signup/Signup")
);
export const SignupSuccess = React.lazy(() =>
  import("../containers/Auth/Success/Success")
);
export const ResetPassword = React.lazy(() =>
  import("../containers/Auth/ResetPassword/ResetPassword")
);
export const ForgetPassword = React.lazy(() =>
  import("../containers/Auth/ForgetPassword/ForgetPassword")
);
export const Login = React.lazy(() => import("../containers/Auth/Login/Login"));

export const MyJobs = React.lazy(() => import("../containers/MyJobs/MyJobs"));
export const MyProfile = React.lazy(() => import("../containers/Profile/Profile"));
export const ContactUs = React.lazy(() =>
  import("../containers/ContactUs/ContactUs")
);
export const ScreeningQuestions = React.lazy(() =>
  import("../containers/ScreeningQuestions/ScreeningQuestions")
);

export const NotificationList = React.lazy(() =>
  import("../containers/NotificationList/NotificationList")
);