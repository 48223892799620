import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import ThemeApp from "./Theme";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/SocialLogins/Microsoft/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ThemeApp />
    </Provider>
  </MsalProvider>,
  document.querySelector("#root")
);
