import { call, put, takeEvery } from "redux-saga/effects";
import API from "./apis";
import * as ACTION_CONTACTUS from "./actions";
import { handleModalStateAction } from '../Modal/actions';
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../utils/Shared";
import * as types from "./types";
import history from "../../routes/History";

export function* contactUsForm(action) {
  try {
    const response = yield call(API.postContactUs, action.payload);
    yield put(ACTION_CONTACTUS.recieveContactUsForm(response.data));

    if (!history.location.pathname.includes("Contact-us")) {
      dispatchSnackbarSuccess("contactModalSuccess");
    } else {
      yield put(handleModalStateAction(true));
    }
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* getContactUsContent() {
  try {
    const response = yield call(API.getContactUs);
    yield put(ACTION_CONTACTUS.recieveContactUsContent(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* ContactUsSagas() {
  yield takeEvery(types.FETCH_CONTACT_US_FORM_REQUEST, contactUsForm);
  yield takeEvery(types.FETCH_CONTACT_US_REQUEST, getContactUsContent);
}
