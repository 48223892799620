
import { call, put } from 'redux-saga/effects';
import API from './apis';
import * as ACTION from './actions';
import * as type from './types';
import { takeLatest } from 'redux-saga/effects';
import { dispatchSnackbarError } from "../../utils/Shared";

export function* popupNotificationRequest() {
    try {
      const response = yield call(API.getNotificationPopupRequest);
      yield put(ACTION.popupNotificationResponse(response.data.data));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
  }

export function* setNotificationVistedRequest({payload}){
  try {
    const response = yield call(API.setNotificationVistedRequest,payload);
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* notificationListRequest({payload}){
  try {
    const response = yield call(API.notificationListRequest,payload);
    yield put(ACTION.notificationListResponse(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* setNotificationSeenRequest(){
  try {
    const response = yield call(API.setNotificationSeenRequest);
    yield put(ACTION.popupNotificationRequest())
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* NotificationSagasWatch() {
    yield takeLatest(type.POPUP_NOTIIFCATION_REQUEST, popupNotificationRequest);
    yield takeLatest(type.SET_NOTIFICATION_VISITED_REQUEST, setNotificationVistedRequest);
    yield takeLatest(type.NOTIFICATION_LIST_REQUEST, notificationListRequest);
    yield takeLatest(type.SET_NOTIFICATION_SEEN, setNotificationSeenRequest);
    
  }
  
 