export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_RECEIVE = 'SIGN_UP_RECEIVE';
export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_RECIEVE = 'FORGET_PASSWORD_RECIEVE'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RECIEVE = 'RESET_PASSWORD_RECIEVE';
export const SEND_EMAIL_AGAIN_REQUEST = "SEND_EMAIL_AGAIN_REQUEST";
export const CONFIRM_EMAIL_REQUEST = "CONFIRM_EMAIL_REQUEST";
export const CONFIRM_EMAIL_RECEIVE = "CONFIRM_EMAIL_RECEIVE";
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_RECEIVE = "SIGN_IN_RECEIVE";
export const VALIDATE_DOMAIN_REQUEST = 'VALIDATE_DOMAIN_REQUEST';
export const VALIDATE_DOMAIN_RECEIVE = 'VALIDATE_DOMAIN_RECEIVE';
export const IS_USER_AUTHENTICATED = 'IS_USER_AUTHENTICATED';
export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';