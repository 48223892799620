import * as type from "./types";

const INITIAL_STATE = {
  countries: [],
  cities: [],
  permissions: [],
  jobRoles: [],
  salaryGrades: [],
  residencies: [],
  nationalities: [],
  languages: [],
  careerLevels: [],
  jobTitles: [],
  colleges: [],
  degrees: [],
  competencies: [],
  companyBranches: [],
  currencies: [],
  jobTitlesCategories: [],
  agents: [],
  companyFields: [],
  jobOpenings: [],
  pipelineFLows: [],
  rejectionReasons: [],
  companySizes: [],
  languagesWithoutEnglish: [],
  companytype: [],
  hearAboutUs: [],
  contractType: [],
  college: [],
  degree: [],
  jobTitle: [],
  jobTitleCategory: [],
  jobCategory: [],
  jobContractType: [],
  skills: [],
  countriesWithCities: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.GET_COUNTRIES_RECEIVE:
      return { ...state, countries: [...action.payload] };
    case type.GET_CITIES_RECEIVE:
      return { ...state, cities: [...action.payload] };
    case type.GET_ALL_PERMISSIONS_RECEIVE:
      return { ...state, permissions: [...action.payload] };
    case type.GET_ALL_JOB_ROLES_LOOKUP_RECEIVE:
      return { ...state, jobRoles: [...action.payload] };
    case type.LOOKUP_SALARY_GRADES:
      return { ...state, salaryGrades: [...action.payload] };
    case type.LOOKUP_RESIDENCIES:
      return { ...state, residencies: [...action.payload] };
    case type.LOOKUP_NATIONALITIES:
      return { ...state, nationalities: [...action.payload] };
    case type.LOOKUP_LANGUAGES:
      return { ...state, languages: [...action.payload] };
    case type.LOOKUP_CAREER_LEVELS:
      return { ...state, careerLevels: [...action.payload] };
    case type.LOOKUP_JOB_TITLES:
      return { ...state, jobTitles: [...action.payload] };
    case type.LOOKUP_COLLEGES:
      return { ...state, colleges: [...action.payload] };
    case type.LOOKUP_DEGREES:
      return { ...state, degrees: [...action.payload] };
    case type.LOOKUP_COMPETENCIES:
      return { ...state, competencies: [...action.payload] };
    case type.LOOKUP_COMPANY_BRANCHES_RECEIVE:
      return { ...state, companyBranches: [...action.payload] };
    case type.LOOKUP_SALARY_GRADES_FILTER_RECEIVE:
      return { ...state, salaryGrades: [...action.payload] };
    case type.LOOKUP_CURRENCY_RECEIVE:
      return { ...state, currencies: [...action.payload] };
    case type.LOOKUP_JOBTITLES_CATEGORIES_RECEIVE:
      return { ...state, jobTitlesCategories: [...action.payload] };
    case type.LOOKUP_AGENTS_RECEIVE:
      return { ...state, agents: [...action.payload] };
    case type.LOOKUP_COMPANY_FIELDS_RECEIVE:
      return { ...state, companyFields: [...action.payload] };
    case type.LOOKUP_GET_JOB_OPENINGS_RECEIVE:
      return { ...state, jobOpenings: [...action.payload] };
    case type.LOOKUP_GET_PIPELINE_FLOWS_RECEIVE:
      return { ...state, pipelineFLows: [...action.payload] };
    case type.LOOKUP_GET_REJECTION_REASONS_RECEIVE:
      return { ...state, rejectionReasons: [...action.payload] };
    case type.LOOKUP_COMPANY_type_RECEIVE:
      return { ...state, companytype: [...action.payload] };
    case type.LOOKUP_COMPANY_SIZES_RECEIVE:
      return { ...state, companySizes: [...action.payload] };
    case type.HEAR_ABOUT_US_LIST_RECEIVE:
      return { ...state, hearAboutUs: [...action.payload] };
    case type.CONTRACT_TYPE_RECEIVE:
      return { ...state, contractType: [...action.payload] };
    case type.COLLEGE_LOOKUP_RECEIVE:
      return { ...state, college: action.payload };
    case type.DEGREE_LOOKUP_RECEIVE:
      return { ...state, degree: action.payload };
    case type.JOB_TITLE_LOOKUP_RECEIVE:
      return { ...state, jobTitle: action.payload };
    case type.JOB_TITLE_FILTER_CATEGORY_LOOKUP_RECEIVE:
      return { ...state, jobTitle: action.payload };
    case type.JOB_TITLE_CATEGORY_LOOKUP_RECEIVE:
      return { ...state, jobTitleCategory: action.payload };
    case type.RESIDENCIES_LOOKUP_RECEIVE:
      return { ...state, residencies: action.payload };
    case type.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_RECEIVE:
      return { ...state, languagesWithoutEnglish: action.payload };
    case type.JOB_CATEGORY_LOOKUP_RECEIVE:
      return { ...state, jobCategory: action.payload };
    case type.JOB_CONTRACT_TYPE_LOOKUP_RECEIVE:
      return { ...state, jobContractType: action.payload };
    case type.COUNTRIES_LOOKUP_RECEIVE:
      return { ...state, countries: action.payload };
    case type.CITIES_LOOKUP_RECEIVE:
      return { ...state, cities: action.payload };
    case type.SKILLS_LOOKUP_RECIEVE:
      return { ...state, skills: action.payload };
    case type.USER_NATIONALITY_RECIEVE:
      return {
        ...state,
        nationalities: action.payload,
      };
    case type.COUNTRIES_WITH_CITIES_LOOKUP_RECEIVE:
      return {
        ...state,
        countriesWithCities: action.payload,
      };
    case type.JOB_TYPES_RECIEVE:
      return {
        ...state,
        jobTypes: action.payload,
      };
    default:
      return state;
  }
};
