export const GET_JOBS_REQUEST = 'GET_JOBS_REQUEST';
export const GET_JOBS_RECEIVE = 'GET_JOBS_RECEIVE';

export const GET_JOB_DETAILS_REQUEST = 'GET_JOB_DETAILS_REQUEST';
export const GET_JOB_DETAILS_RECEIVE = 'GET_JOB_DETAILS_RECEIVE';

export const ADD_REMOVE_FAVORITE_JOBS = 'ADD_REMOVE_FAVORITE_JOBS'

export const ADD_JOB_FILTERS_REQUEST = 'ADD_JOB_FILTERS_REQUEST'

export const GET_MY_JOBS_APPLIED_REQUEST = 'GET_MY_JOBS_APPLIED_REQUEST';
export const GET_MY_JOBS_APPLIED_RECEIVE = 'GET_MY_JOBS_APPLIED_RECEIVE';

export const GET_MY_JOBS_SAVED_REQUEST = 'GET_MY_JOBS_SAVED_REQUEST';
export const GET_MY_JOBS_SAVED_RECEIVE = 'GET_MY_JOBS_SAVED_RECEIVE';
export const GET_LATEST_JOBS_REQUEST = 'GET_LATEST_JOBS_REQUEST';
export const GET_LATEST_JOBS_RECIEVE = 'GET_LATEST_JOBS_RECIEVE';
export const UPDATE_JOB_FAVORITE_REQUEST = "UPDATE_JOB_FAVORITE_REQUEST";
export const UPDATE_JOB_FAVORITE_RECIEVE = "UPDATE_JOB_FAVORITE_RECIEVE";
export const SHARE_JOB_REQUEST = "SHARE_JOB_REQUEST";
export const SHARE_JOB_RECIEVE = "SHARE_JOB_RECIEVE";