import * as types from './types';

const INITIAL_STATE = {
  topCompanies: [],
  hideCompanies: true,
  reset: false,
  sharingStatusDone: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_TOP_COMPANIES_RECIEVE:
      return {
        ...state,
        topCompanies: action.payload,
      };
    case types.RESET_TOP_COMPANIES_RECIEVE:
      return {
        ...state,
        reset: action.payload,
      };
    case types.HIDE_TOP_COMPANIES:
      return {
        ...state,
        hideCompanies: action.payload,
      };
    case types.SHARE_CANDIDATE_RECIEVE:
      return {
        ...state,
        sharingStatusDone: true,
      };
    default:
      return state;
  }
};
