import * as types from "./types";
import Cookies from "js-cookie";
import { getEnv } from "../../utils/Shared";

const INITIAL_STATE = {
  data: {},
  userData: {},
  is_authenticated: Cookies.get("token", { domain:  getEnv()}) ? true : false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SIGN_IN_RECEIVE:
      return {
        ...state,
        userData: action.payload,
      };
    case types.FORGET_PASSWORD_RECIEVE:
      return {
        ...state,
        data: action.payload,
      };
    case types.RESET_PASSWORD_RECIEVE:
      return {
        ...state,
        data: action.payload,
      };
    case types.IS_USER_AUTHENTICATED:
      return {
        ...state,
        is_authenticated: action.payload,
      };
    default:
      return state;
  }
};
