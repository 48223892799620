import { call, put } from "redux-saga/effects";
import API from "./api";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./types";

export function* browseByIndustryRequest() {
  try {
    const response = yield call(API.getBrowseByIndustry);
    yield put(ACTIONS.browseByIndustryReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}


export function* getBrowseByIndustry() {
  yield takeLatest(TYPES.BROWSE_BY_INDUSTRY_REQUEST, browseByIndustryRequest);
}
