import { call, put } from "redux-saga/effects";
import API from "./apis";
import * as ACTIONS from "./actions";
import { takeLatest } from "redux-saga/effects";
import * as TYPE from "./types";
import { dispatchSnackbarError } from "../../utils/Shared";
import History from "../../routes/History";
import ReactGA from "react-ga";
import {
  dispatchSnackbarSuccess
} from "../../utils/Shared";
export function* jobPreferenceRequest({ payload }) {
  try {
    const response = yield call(API.jobPreferenceRequest, payload);
    yield put(ACTIONS.jobPreferenceReceive(response.data));
    ReactGA.event({
      category: "Job Preference",
      action: "User pressed Job Preference button",
      label: localStorage.getItem("domain"),
    });
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* deletejobPreferenceRequest({ payload, activePage }) {
  try {
    yield call(API.deletejobPreference, payload);
    dispatchSnackbarSuccess("done");
    yield put(ACTIONS.jobPreferenceRequest({ page_number: activePage, page_size: 9 }));
    setTimeout(() => (History.push(`/JobPreferences?page=${activePage}`)), 100);
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}
export function* addjobPreferenceRequest({ payload }) {
  try {
    yield call(API.addJobPreference, payload);
    dispatchSnackbarSuccess("savedSuccessfuly");
    setTimeout(() => (window.location.href = "/JobPreferences?page=1"), 100);
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* editjobPreferenceRequest({ payload }) {
  try {
    yield call(API.editJobPreference, payload);
    dispatchSnackbarSuccess("savedSuccessfuly");
    setTimeout(() => (window.location.href = "/JobPreferences?page=1"), 100);
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* getJobPreferenceRequest({ payload }) {
  try {
    const response = yield call(API.getJobPreferenceRequest, payload);
    yield put(ACTIONS.getJobPreferenceRecieve(response.data.data));

  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* addjobPreferencebyJobRequest({ payload }) {
  try {
    yield call(API.addJobPreferenceByJobRequest, payload);
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* jobPreferenceSagasWatch() {
  yield takeLatest(TYPE.JOB_PREFERENCE_REQUSET, jobPreferenceRequest);
  yield takeLatest(TYPE.DELETE_JOB_PREFERENCE_REQUSET, deletejobPreferenceRequest);
  yield takeLatest(TYPE.ADD_JOB_PREFERENCE_REQUEST, addjobPreferenceRequest);
  yield takeLatest(TYPE.GET_JOB_PREFERENCE_REQUEST, getJobPreferenceRequest);
  yield takeLatest(TYPE.EDIT_JOB_PREFERENCE_REQUEST, editjobPreferenceRequest);
  yield takeLatest(TYPE.ADD_JOB_PREFERENCE_BY_JOB_REQUEST, addjobPreferencebyJobRequest);

}
