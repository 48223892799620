import { call, put, takeLatest } from "redux-saga/effects";
import API from "./apis";
import * as ACTION from "./actions";
import {
  dispatchSnackbarError,
  getURLParams,
  getEnv,
} from "../../utils/Shared";
import History from "../../routes/History";
import * as TYPE from "./types";
import Cookies from "js-cookie";

const autoLogin = (response) => {
  Cookies.set("token", response.data.data.token, { expires: 1, domain: getEnv() });
  Cookies.set("userData", response.data.data, { expires: 1, domain: getEnv() });
  if (getURLParams("domain")) {
    (window.location.href = getURLParams("page")
      ? `${response.data.data.redirect_url}/${getURLParams(
        "page"
      )}?id=${getURLParams("id")}`
      : response.data.data.redirect_url)
  } else {
    if ((response.data.data.job_post_id !== null) || (response.data.data.job_post_id !== undefined)) {
      window.location.replace(`${response.data.data.redirect_url}`);
    }
    else {
      History.push("/");
    }
  }
}

export function* signupRequest({ payload }) {
  try {
    const response = yield call(API.signupRequest, payload);
    getURLParams("domain")
      ? History.push(`/auth/success?domain=${getURLParams("domain")}`)
      : History.push(`/auth/success`);
    Cookies.set("registerationUserId", response.data.data.user_id);
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* sendEmailAgainRequest({ payload }) {
  try {
    const response = yield call(API.sendEmailAgainRequest, payload);
    Cookies.set("registerationUserId", response.data.data.user_id);
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* confirmEmailRequest({ payload }) {
  try {
    const response = yield call(API.confirmEmailRequest, payload);
    yield put(ACTION.signinReceive(response.data.data));
    autoLogin(response)
    yield put(ACTION.isAuthenticated(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* forgetPasswordRequest({ payload }) {
  try {
    const forgetPasRes = yield call(API.forgetPasswordRequest, {
      email: payload.email,
      ...(payload?.domain !== null && { domain: payload?.domain })
    });
    yield put(ACTION.forgetPasswordReceive(forgetPasRes.data));
    getURLParams("domain")
      ? History.push(`/auth/login?domain=${getURLParams("domain")}`)
      : History.push(`/auth/login`);
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* signinRequest({ payload }) {
  try {
    const response = yield call(API.signinRequest, payload);
    yield put(ACTION.signinReceive(response.data.data));
    autoLogin(response)

    yield put(ACTION.isAuthenticated(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* signinWithSocialSDKRequest({ payload }) {
  try {
    const response = yield call(API.socialLoginsRequest, payload);
    yield put(ACTION.signinReceive(response.data.data));
    autoLogin(response)
    
    yield put(ACTION.isAuthenticated(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* resetPasswordRequest({ payload }) {
  try {
    const response = yield call(API.resetPasswordRequest, payload);
    yield put(ACTION.signinReceive(response.data.data));
    autoLogin(response)
    yield put(ACTION.isAuthenticated(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* AuthSagasWatch() {
  yield takeLatest(TYPE.SIGN_UP_REQUEST, signupRequest);
  yield takeLatest(TYPE.FORGET_PASSWORD_REQUEST, forgetPasswordRequest);
  yield takeLatest(TYPE.CONFIRM_EMAIL_REQUEST, confirmEmailRequest);
  yield takeLatest(TYPE.RESET_PASSWORD_REQUEST, resetPasswordRequest);
  yield takeLatest(TYPE.SEND_EMAIL_AGAIN_REQUEST, sendEmailAgainRequest);
  yield takeLatest(TYPE.SIGN_IN_REQUEST, signinRequest);
  yield takeLatest(TYPE.SOCIAL_LOGIN_REQUEST, signinWithSocialSDKRequest);
}
