import * as types from './types';

export const getTopCompaniesRequest = (payload) => ({
  type: types.GET_TOP_COMPANIES_REQUEST,
  payload,
});

export const getTopCompaniesRecieve = (data) => ({
  type: types.GET_TOP_COMPANIES_RECIEVE,
  payload: data,
});
export const hideTopCompanies = (data) => ({
  type: types.HIDE_TOP_COMPANIES,
  payload: data,
});
export const resetTopCompanies = (data) => ({
  type: types.RESET_TOP_COMPANIES_RECIEVE,
  payload: data,
});
export const allowCandidateSharing = (data) => ({
  type: types.SHARE_CANDIDATE_REQUEST,
  payload: data,
});
export const allowCandidateSharingRecieve = () => ({
  type: types.SHARE_CANDIDATE_RECIEVE,
});
