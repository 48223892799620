import * as types from "./types";

export const allCompaniesRequest = (payload) => ({
  type: types.ALL_COMPANIES_REQUEST,
  payload
});

export const allCompaniesReceive = payload => ({
  type: types.ALL_COMPANIES_RECEIVE,
  payload
});
