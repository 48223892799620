export default {
  ar: {
    hello: "مرحبا",
    langBtn: "English",
    signIn: "تسجيل الدخول",
    contactUs: "تواصل معنا",
    contactModalSuccess: "شكرًا على اتصالك بنا ، لقد تم إرسال رسالتك بنجاح",
    contact_us_title: "تواصل معنا",
    contact_us_content:
      "لمزيد من المعلومات , املأ البيانات التالية و سنقوم بالتواصل معك",
    signUp: "سجل",
    privacyPolicy: "سياسة الخصوصية",
    termsAndConditions: "الأحكام والشروط",
    disclaimer: "إخلاء المسؤولية",
    tabsHeader: {
      personal: "المعلومات الشخصية",
      advertQuestions: "الاستطلاع",
      skills: "المهارات",
      education: "التعليم",
      workExperience: "الخبرات",
      reference: "المرجع",
      licenses: "التراخيص والشهادات",
      notifications: "الإشعارات",
      profilePreview: "مراجعة البيانات",
    },
    socialLogin: {
      sectionTitle: "أو بواسطة",
      googleBtn: "Google",
      linkedinBtn: "Linkedin",
      microsoftBtn: "Microsoft",
    },
    vacancyInner: {
      jobDetailsHeader: "تفاصيل الوظيفة",
      jobDetailsHeader: "تفاصيل الوظيفة",
      notifyMe: "إعلامي بوجود وظيفة جديدة",
      notify: "إعلامي",
      jobLocation: "موقع الوظيفة",
      apply: "تقديم",
      profileErrMsg: "برجاء إكمال الملف الشخصى للتمكن من التقديم على الوظائف",
      companyBrief: "كلمة عن الشركة",
      ReferToFriend: "ترشيح إلى صديق",
    },
    common: {
      NoDataFound: "لا يوجد بيانات",
      today: "اليوم",
      days: "ايام",
      day: "يوم",
      or: "او",
      add: "أضف",
      no: "لا",
      yes: "نعم",
      TotalRecords: "اجمالي السجلات",
    },
    educationExperience: {
      college: "الكلية",
      InstituteSchool: "الجهة التعليمية",
      degree: "الدرجة العلمية",
      FieldofStudy: "التخصص",
      gpa: "الدرجة /المعدل التراكمي",
      category: "تصنيف",
      jobTitle: "اسم الوظيفة",
      companyName: "اسم الشركة",
      location: "الموقع",
      from: "من",
      to: "إلى",
      description: "الوصف",
      addEducation: "إضافة المؤهل",
      editEducation: "تعديل المؤهل",
      toPresent: "إلى الوقت الحالى",
      addWorkExperience: "إضافة الخبرة",
      editWorkExperience: "تعديل الخبرة",
      present: "الوقت الحالى",
      outOf: "من أصل",
      customJobTitle: "ادخل اسم الوظيفة",
    },
    personalInfo: {
      personalInfoTitle: "معلومات شخصية",
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      dateOfBirth: "تاريخ الميلاد",
      residency: "بلد الإقامة",
      city: "مدينة الإقامة",
      gender: "الجنس",
      maritalStatus: "الحالة الاجتماعية",
      contactDetails: "بيانات الاتصال",
      phoneNumber: "رقم الجوال",
      personalEmail: "البريد الالكترونى",
      parseCvTitle: "أكمل ملفك الشخصي بسهولة باستخدام الذكاء الصناعي!",
      parsedCvContent:
        " قم  بإرفاق سيرتك الذاتية، وسيقوم نظامنا بتحليلها واستخراج معلوماتك",
      address: "العنوان",
      male: "ذكر",
      female: "انثى",
      nationality: "الجنسية",
      profileTitle: "ارتقِ بملفك الشخصي وتطابق مع أسلوبك",
    },
    skills: {
      skillsInfoTitle: "المهارات",
      profLevel: "مستوى الكفاءة",
      engProLevel: "ما هو مستوى إتقانك للغة الإنجليزية؟",
      languages: "اللغات",
      profLevelInEnglish: "مستوى إتقانك للغة الإنجليزية ",
      motherTongue: "اللغة الام",
    },
    licenses: {
      certificateName: "اسم الشهادة",
      issuingOrganization: "منظمة الإصدار",
      issuedDate: "تاريخ الاصدار",
      expirationDate: "تاريخ الصلاحية",
      noExpirationDate: "لا يوجد تاريخ صلاحية لهذه الشهادة",
    },
    filter: {
      postedDate: "تاريخ النشر",
      jobCategory: "نوع الوظيفة",
      contractType: "عقد العمل",
      filter: "تصفية",
      reset: "اعادة ضبط",
      jobTitle: "المسمى الوظيفى",
      country: "الدولة",
      city: "المدينة",
      search: "بحث",
      resetSearch: "اعادة",
    },
    screening: {
      screeningHeader: "برجاء ملئ الاسئلة التالية:",
      date: "التاريخ",
      time: "الوقت",
      time_type: "النوع",
      text: "الاجابة",
    },
    advertQuestionsInfo: {
      advertQuestionsTitle: "الاستطلاع",
      currentLastSalary: "المرتب الحالى",
      currency: "العملة",
      currentLastPosition: "الوظيفة الحالية",
      currentLastCompany: "الشركة الحالية",
      unemployee: "باحث عن عمل",
      employed: "موظف",
      employeeStatus: "حالة الوظيفه",
    },
    moreInfoQuestions: {
      moreInfoRequest: "طلب معلومات إضافية",
      answersNeededMsg: "الأسئلة التالية يجب الإجابة عليها",
      uploadVideo: "ارفع الفيديو",
      answerTip: "الإجابة مفترض ان تكون في عدد",
      minutes: "دقائق",
      letters: "حروف",
      expirationDate: "ينتهي في",
      answer: "الاجابة",
      submittedBefore: "شكرا لك, لقد قمت برفع الملفات من قبل",
      expiredForm: "شكرا لك, لقد قمت برفع الملفات من قبل",
      requestChangeingDate: " طلب تغيير التاريخ",
      requestChangeDateContent:
        " تاريخ انتهاء الطلب هو اخر تاريخ انتهاء، ولكن يمكنكم طلب تغيير هذا إن أمكن  من فضلك اختر التاريخ، وانتظر الموافقة",
      newExpirationDate: "تاريخ الانتهاء الجديد",
      reason: "السبب",
      previewVideo: "تشغيل الفيديو",
    },
    navbar: {
      home: "الرئيسية",
      jobs: "الوظائف",
      companies: "الشركات",
      contactUs: "تواصل معنا",
      signIn: "تسجيل الدخول",
      signUp: "سجل",
      forCompanies: "صاحب شركة",
      myApplications: "وظائفى",
      JobPreferences: "الوظائف المفضلة",
    },
    auth: {
      signup: "سجل ",
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      email: "البريد الالكترونى",
      forgetPassword: "نسيت كلمة المرور ؟",

      successRegister2: ` يرجى التحقق من بريدك الإلكتروني (يرجى التحقق ايضا" من مجلد الرسائل غير المرغوب فيها او البريد العشوائي ؛ إذا لم تتلق رسالة التأكيد الإلكترونية في غضون دقيقة واحدة ، فيرجى الضغط على "إرسال مرة أخرى")`,
      successRegister1: ` تم ارسال بريد الكتروني للتأكيد الى`,

      password: "كلمة المرور",
      confirmPassword: "تاكيد كلمة المرور",
      passwordValidRules: "يجب أن تتبع كلمة المرور القوية هذه القواعد:",
      passwordValidTipMin: "على الأقل 8 أحرف",
      passwordValidTipUpperLower: "مزيج من الأحرف الكبيرة والصغيرة",
      passwordValidTipLetterNumber: "مزيج من الحروف والأرقام",
      ForgetPasswordHeading: "نسيت كلمة المرور",
      Reset: "استعادة كلمة المرور",
      signin: "تسجيل الدخول",
      signupDesign: "سجل معنا للتقديم على الوظائف بسهولة",
      addPassword: "أضف كلمة المرور",
      signupMsg: "ليس لديك حساب؟ إنشاء حساب",
      signinMsg: "لديك حساب؟ سجل دخول",
      hearAboutUs: "كيف سمعت عنا؟",
      signUpAgreeMsg: "بالتسجيل معنا ، فإنك توافق على ",
      signUpSharindDataAgreeMsg:
        "بالضغط هنا ، فإنك تسمح لنا بمشاركة ملفك مع عملائنا ",
      loginSharindDataAgreeMsg:
        "بالضغط علي سماح ، فإنك تسمح لنا بمشاركة ملفك مع عملائنا",
      allow: "سماح",
      disallow: "عدم سماح",
      sharing: "السماح بالنشر",
      welcomeTo: 'أهلا" بك',
      signinTitle: "في موقعك الخاص بالوظائف",
      signinSubTitle: "اكتشف الشواغر الآن",
      signupTitle: " في ملتقى الباحثين عن عمل",
      signUpSubTitle: "ابدأ رحلتك",
    },
    footer: {
      aboutUs: "تعرف علينا",
      addressTitle: "العنوان",
      address:
        "الرياض , المملكة العربية السعودية , شارع العليا العام - طريق النور",
      privacyPolicy: "سياسة الخصوصية",
      termsAndConditions: "الأحكام والشروط",
      disclaimer: "إخلاء المسؤولية",
      ourProducts: "خدماتنا",
      startFreeTrial: "ابدأ تجربتك المجانية",
      postFreeJob: 'أعلن عن وظيفة مجانا"',
      searchForJobs: "البحث عن وظيفة",
    },
    shared: {
      candidatePortal: "بوابة المتقدمين",
      seeAll: "عرض الكل",
      copied: "تم النسخ بنجاح",
      candidateCompany: "بوابة المتقدمين",
      done: "تم بنجاح",
      savedSuccessfuly: "لقد تم حفظ البيانات بنجاح",
      deactivatedCompany:
        "هذه البوابة غير متاحة الآن. الرجاء معاودة المحاولة في وقت لاحق!",
      interview: "المقابلة",
      logout: "الخروج",
      profile: "الملف الشخصى",
      view: "عرض",
      results: "النتائج",
      noDataFound: "لا يوجد بيانات",
      easyApply: "سهل التقديم",
      myJobs: "وظائفى",
      noJobskeyword: "لا يوجد وظائف",
      oneJobKeyword: "وظيفة واحدة",
      twoJobs: "وظيفتان",
      morethanTwoJob: "وظائف",
      morethantenJobs: "وظيفة",
      send_again: "ارسل مرة اخرى",
      address: "العنوان",
      submit: "ارسال",
      email: "البريد الالكتروني",
      message: "الرسالة",
      message_placeholder: "اكتب رسالتك هنا",
      register: "تسجيل",
      add_password_title: "اضف كلمة المرور",
      registeryourCompany: "سجل شركتك",
      fill_all_form: "*برجاء ملئ جميع الحقول",
      success_register:
        " تم إرسال بريد إلكتروني للتأكيد ، يرجى التحقق من بريدك الإلكتروني.  إذا لم تتلق رسالة التأكيد الإلكترونية في غضون دقيقة واحدة ، فيرجى الضغط على إرسال مرة أخرى",
      notFoundRecords: "لا توجد بيانات",
      name: "الاسم",
      phone: "الهاتف",
      file:'الملف'
    },
    alljobs: {
      easyApply: "سهل التقديم",
      jobDetails: "تفاصيل  الوظيفة",
      skills: "المهارات المطلوبة",
      apply: "التقديم للوظيفة",
      postJob: "نشر",
    },
    errors: {
      FileMaxSize5MB: "يجب ان لا يتعدى حجم الملف 10 ميجا بايت",
      fillAllForm: "*برجاء ملئ جميع الحقول",
      required: "هذا الحقل مطلوب",
      invalidEmail: "بريد الكترونى غير صحيح",
      nameError: "هذا الحقل يجب الا يتضمن رموز خاصة",

      invalidPassword:
        " رمز مرور خاطئ الاحرف على الاقل 8 و مزيج من الاحرف الكبيرة و الصغيرة و الارقام",
      confirmPasswordMatch: "كلمة المرور وتأكيد كلمة المرور لا تتطابق",
      imageRatio: "يجب ان تكون نسبة الصورة ١:١",
      invalidGPAValue: "يجب ان يكون الرقم مابين 0 - ",
      toGreaterThanFrom: "يجب ان تكون 'الى' اكبر 'من' ",
      chooseAnswers: "برجاء اختيار اجاباتك",
      selctedLanguageBefore: "لقد قمت باختيار هذه اللغة من قبل",
      pdfWordFilesAllowed: "PDF, Word مسموح فقط بملفات",
      pdfImagesFilesAllowed: "مسموح فقط بملفات PDF, Word, JPG, PNG, JPEG",
      allowedSize: "يجب أن لا يتعدى حجم الملف 10 ميجا بايت",
      allowedVideoSize: "الحجم المسموح",
      videoAllowedTypes: "mp4 مسموح فقط بملفات",
      termsConditionsErr:
        "يجب ان توافق على الشروط و الاحكام وسياسة الخصوصية و  إخلاء المسؤولية",
    },
    snackbar: {
      successApply: "شكرا لتقديمك على الوظيفة ، ستسمع منا قريبا",
      successFormSubmit: "شكرا تم تقديم النموذج بنجاح",
      contactModalSuccess: "شكرًا على اتصالك بنا ، لقد تم إرسال رسالتك بنجاح",
      done: "تم بنجاح",
      savedSuccessfuly: "تم حفظ البيانات بنجاح",
      successApprovedOffer: "شكرا لقبولك العرض, نتطلع إلى بدء العمل معك",
    },
    home: {
      latestJobs: "احدث الوظائف",
      jobByCity: "الوظيفة حسب المدينة",
      browseByIndustry: "تصفح حسب المجال",
      PostedNewJobs: "+ وظيفة تم نشرها",
      FindYour: "إبحث عن",
      DreamJobHere: "وظيفة احلامك",
      viewJob: "عرض الوظيفة",
      here: "هنا",
      byCompanies: "وظائف حسب الشركة",
      byCompaniesContnetMiniTitle: "مستقبلك المهني يبدأ من هنا!",
      byCompaniesContent:
        "انتهز فرصة التواصل المباشر مع أصحاب الشركات ومديري التوظيف، واكتشف الآن أفضل الوظائف المناسبة لك في مجالك.",
      registeration: {
        title: "استفيد بالعديد من الفرص الوظيفية المتاحة",
        title2: "في نظامنا!",
        subTitle: "سجل الآن واحصل على أفضل الوظائف في مجالك.",
      },
      searchForJob: ["ابحث عن وظيفتك ", "المفضلة الآن!"],
      CompaniesTitle: ["واصل مع أفضل الشركات و", "ووسع شبكة العلاقات!"],
    },
    industries: "المجالات",
    Companies: "الشركات",
    visitWebsite: "زيارة الموقع",
    buttons: {
      sendAgain: "ارسل مرة اخرى",
      readMore: "قراءة المزيد",
      copyUrl: "إنسخ الرابط",
      register: "تسجيل",
      registerNow: "سجل الأن",
      searchCompanies: "استكشف الشركات",
      login: "تسجيل الدخول",
      addSection: "أضف قسم",
      save: "حفظ",
      cancel: "إلغاء",
      downloadAttachment: "تحميل الملف المرفق",
      uploadCv: "أرفع سيرتك الذاتيه",
      dragCV: " أو أدرجها",
      startInterview: "بدء المقابلة",
      uploadFile: "ارفع ملف",
      submit: "ارسال",
      reset: "مسح",
      uploadImage: "رفع صورة",
      download: "تحميل",
      accept: "قبول",
      decline: "رفض",
      sendforreview: " إرسال للتفاوض",
      search: "بحث",
      resetSearch: "مسح الكل",
      filter: "تصفية",
      sendforreview: " إرسال للتفاوض",
      edit: "تعديل",
      delete: "مسح",
      seeMore: "انظر المزيد",
    },
    jobPreferences: {
      jobTitle: "المسمى الوظيفي",
      location: "الموقع (مدينة، دولة)",
      jobType: "نوع الوظيفة",
    },
    browseByIndustry: "تصفح حسب المجال",
    seeAll: "عرض الكل",
    filters: {
      country: "الدولة",
      city: "المدينة",
      name: "الاسم",
      homeSearchKeyword: "اسم أو مسمي وظيفي",
      companyNameORjobTitle: "اسم الشركة أو اسم الوظيفة",
      followedCompanies: "الشركات المتبعة",
      unFollowedCompanies: "الشركات الغير متبعة",
      all: "الكل",
      contractType: "نوع العقد",
      sortBy: "الترتيب حسب",
      sortOrder: " نوع الترتيب",
      easyApplyOnly: "سهل التقديم فقط",
    },
    myJobs: {
      appliedJobs: "الوظائف المتقدم لها",
      savedJobs: "الوظائف المفضلة",
      jobTitle: "Job Title",
      status: "الحالة",
      appliedOn: "تقدمت في تاريخ",
      companyName: "اسم الشركة",
      companyLogo: "Company logo",
      companyBranch: "اسم الفرع",
      contractType: "نوع العقد",
      postingDate: "تم النشر",
      easyToApply: "Easy to apply",
      jobDescription: "Job description",
      applied: "تم التقديم",
      inProgress: "قيد التنفيذ",
      closed: "مغلق",
    },
    jobPreferences: {
      delete: "مسح",
      deleteMsg: "هل ترغب في حذف هذه الوظيفة المفضلة؟",
      TellUs:
        "أخبرنا بما تبحث عنه في الوظيفة وسنستخدم هذه المعلومات لإقتراح أفضل الوظائف لك",
      WhatJob: "ما المسميات الوظيفية التي تبحث عنها؟",
      JobTitle: "المسمى الوظيفي",
      jobTypes: "أنواع الوظائف",
      whatlocations: "ما هي المواقع التي تفضل العمل فيها؟",
      WhatTypes: "ما هي أنواع الوظائف التي تحبذها؟",
      NewjobNotification: "تنبيه بوظيفة جديدة",
      NewjobEmails: "إرسال بريد إلكتروني للوظائف الجديدة",
    },
    contact_us_content: {
      contact_us_title: "أعرف أكثر عن النظام هنا!",
      contact_us_content:
        "لمزيد من المعلومات، املأ البيانات التالية وسنقوم بالتواصل معك والرد عليك",
      gladTo: "فخـورون بـوجــودك",
      here: "هــنا !",
      getInTouch: "تواصــل معنــا ,",
      happyToHelp: "وسنكون سعداء بمساعدتك في أي وقت!",
      companies_questions: "أسئلة الشركات",
      candidates_questions: "أسئلة المرشحين",
      companies: "للــشركات",
      candidates: "للــمرشحين",
    },
    faqs: {
      clients: {
        faq1: {
          question: "ما هو نظام HRCOM ؟",
          answer: "نظام تتبع للمتقدمين يسهل عليك عملية التوظيف بأكملها.",
        },
        faq2: {
          question: "هل يمكن استخراج البيانات؟",
          answer: "بالطبع يمكنك استخراجها من خلال طلب الحصول على البيانات.",
        },
        faq3: {
          question: "هل يتم حفظ بيانات الحساب المجاني إذا قمت بتفعيل الشراء؟",
          answer: "يتم الاحتفاظ بالإعدادات والبيانات لك دائماً.",
        },
        faq4: {
          question: "هل من السهل التحويل من نظام توظيف آخر إلى عالمنا؟",
          answer: "بالطبع، سيسهل عليك فريقنا التقني جميع خطوات الدمج والتدريب.",
        },
        faq5: {
          question: "مَن سيستفيد مِن استخدام نظامنا؟",
          answer:
            "أي شركة ترغب في تحسين عملية التوظيف لديها وجذب أكبر المواهب في العالم.",
        },
        faq6: {
          question: "هل يوجد أي مصاريف للحساب؟",
          answer: "لا يوجد، فقط رسوم الاشتراك.",
        },
        faq7: {
          question: "هل هناك رسوم على إلغاء الاشتراك؟",
          answer: "لا شئ على الإطلاق.",
        },
      },
      candidates: {
        faq1: {
          question: "أين تحفظ بياناتي في النظام؟",
          answer:
            "يتم حفظ البيانات واستخدامها عن طريق الشركات المتواجدة داخل النظام لإرسال عروض العمل بسهولة إليك.",
        },
        faq2: {
          question: "هل مقابلات العمل تتم من خلالكم أم من خلال الشركة؟ ",
          answer: "الشركات المسجلة لدينا تقوم بكافة عملية التوظيف.",
        },
        faq3: {
          question: "كيف يمكن الاستفادة من نظام إتش أر كوم؟",
          answer:
            "يمكنك إنشاء ملف خاص بك والتقديم على الوظائف من خلاله، أو التقدم لوظيفة مباشرة بشكل أسهل.",
        },
      },
    },
  },
};
