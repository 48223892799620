import { call, put } from "redux-saga/effects";
import * as API from "./apis";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./types";
import History from "../../routes/History";
import store from "..";

export function* getAllJobsRequest({ payload }) {
  try {
    Object.keys(payload).forEach((item) => {
      if (
        item === "city" ||
        item === "industry" ||
        item === "country" ||
        item === "contract_type"
      ) {
        payload[item] = Number(payload[item]);
      }
    });
    const response = yield call(API.getJobs, payload);
    yield put(ACTIONS.getJobsReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* getJobDetailsRequest({ payload }) {
  try {
    const response = yield call(API.getJobDetails, payload);
    yield put(ACTIONS.getJobDetailsReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* getLatestJobs({ payload }) {
  const { resetJobs, contract_type, page_size, page_number } = payload;
  try {
    const response = yield call(API.getLatestJobs, {
      contract_type,
      page_size,
      page_number,
    });
    const data = response.data.data;
    if (resetJobs) {
      yield put(ACTIONS.getLatestJobsRecieve([]));
    }
    yield put(
      ACTIONS.getLatestJobsRecieve([
        ...store.getState().jobs.latestJobs,
        ...data,
      ])
    );
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* addRemoveFavoriteJobsRequest({ payload }) {
  try {
    yield call(API.addRemoveFavoriteJobs, payload);
    // yield put(ACTIONS.getJobsRequest());
  } catch (err) {
    if (err.response.status === 401) {
      History.push("/auth/login");
    }
    dispatchSnackbarError(err.response?.data);
  }
}

export function* getMyJobsApplied({ payload }) {
  try {
    const response = yield call(API.getMyJobsApplied, payload);
    yield put(ACTIONS.getMyJobsAppliedReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* shareJobRequest({ payload }) {
  try {
    const response = yield call(API.shareJobRequest, payload);
    yield put(ACTIONS.shareJobRecieve(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* getMyJobsSaved({ payload }) {
  try {
    const response = yield call(API.getMyJobsSaved, payload);
    yield put(ACTIONS.getMyJobsSavedReceive(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      History.push("/auth/login");
    }
    dispatchSnackbarError(err.response?.data);
  }
}
export function* updateJobWithFavorite({payload}) {
  try {
    if (payload.type === "latestjobs") {
      const result = store.getState().jobs.latestJobs.map((item) => {
        if (item.job_post_id === payload.id) {
          item.is_favorite = !item.is_favorite;
        }
        return item;
      });
      yield put(ACTIONS.getLatestJobsRecieve(result));
    }
    if (payload.type === "jobdetails") {
      const data = store.getState().jobs.jobsList?.data.map((item) => {
        if (item.job_post_id === payload.id) {
          item.is_favorite = !item.is_favorite;
        }
        return item;
      });
      yield put(ACTIONS.getJobsReceive({
        data,
        paging: store.getState().jobs.jobsList.paging
      }));
      const jobDescription = store.getState().jobs.jobDetails;
      const result = {
        ...jobDescription,
        is_favorite: !jobDescription.is_favorite,
      };
      yield put(ACTIONS.getJobDetailsReceive(result));
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* jobsSagas() {
  yield takeLatest(TYPES.GET_JOBS_REQUEST, getAllJobsRequest);
  yield takeLatest(TYPES.GET_JOB_DETAILS_REQUEST, getJobDetailsRequest);
  yield takeLatest(
    TYPES.ADD_REMOVE_FAVORITE_JOBS,
    addRemoveFavoriteJobsRequest
  );
  yield takeLatest(TYPES.GET_MY_JOBS_APPLIED_REQUEST, getMyJobsApplied);
  yield takeLatest(TYPES.GET_MY_JOBS_SAVED_REQUEST, getMyJobsSaved);
  yield takeLatest(TYPES.GET_LATEST_JOBS_REQUEST, getLatestJobs);
  yield takeLatest(
    TYPES.UPDATE_JOB_FAVORITE_REQUEST,
    updateJobWithFavorite
  );
  yield takeLatest(
    TYPES.SHARE_JOB_REQUEST,
    shareJobRequest
  );
}
