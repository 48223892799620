import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../store/Profile/apis";
import * as ACTIONS from "./actions";
import * as TYPE from "./types";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
  colorsWithPlan,
  logoWithPlan,
  candidatePortalLogo
} from "../../utils/Shared";
import store from "../../store";

export function* getUserProfile() {
  try {
    const response = yield call(API.getUserProfile);
    yield put(ACTIONS.userProfileReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* uploadCv({ payload }) {
  try {
    const response = yield call(API.uploadCvReqeust, payload);
    yield put(ACTIONS.uploadCvReceive(response.data.data));
    yield put(ACTIONS.userProfileRequest());
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* uploadImage({ payload }) {
  try {
    const response = yield call(API.uploadImageReqeust, payload);
    yield put(ACTIONS.uploadImageReceive(response.data.data));
    store.dispatch(ACTIONS.userProfileRequest());
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* deleteEducationInfoRequest({ payload }) {
  try {
    yield call(API.deleteEducationInfoRequest, payload);
    yield put(ACTIONS.userProfileRequest());
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* saveWorkExperienceInfoRequest({ payload }) {
  try {
    yield call(API.saveWorkExperienceInfoRequest, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* deleteWorkExperienceInfoRequest({ payload }) {
  try {
    yield call(API.deleteWorkExperienceInfoRequest, payload);
    yield put(ACTIONS.userProfileRequest());
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* saveEducationInfoRequest({ payload }) {
  try {
    yield call(API.saveEducationInfoRequest, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* saveCVInfoRequest({ payload }) {
  try {
    yield call(API.saveCVInfoRequest, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* editPersonalInfoRequest({ payload }) {
  try {
    yield call(API.updatePersonalInfo, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* saveSkillsInfoRequest({ payload }) {
  try {
    yield call(API.saveSkillsInfoRequest, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* saveSimpleProfile({ payload }) {
  try {
    yield call(API.saveSimpleProfile, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* saveAdvertQuestionsRequest({ payload }) {
  try {
    yield call(API.updateAdvertQuestionsRequest, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* disabelAdvertQuestions() {
  try {
    const response = yield call(API.disbaleAdvertQuestions);
    yield put(ACTIONS.disableAdvertQuestionsReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* saveCertificatesLicenses({ payload }) {
  try {
    yield call(API.saveCertificatesLicenses, payload);
    yield put(ACTIONS.userProfileRequest());
    dispatchSnackbarSuccess("savedSuccessfuly");
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* deleteCertificatesLicensesRequest({ payload }) {
  try {
    yield call(API.deleteCertificatesLicenses, payload);
    yield put(ACTIONS.userProfileRequest());
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* CandidateCompanyRequest({ payload }) {
  try {
    const response = yield call(API.getCandidateCompanyRequest, payload);
    const brandedSite = colorsWithPlan(response.data?.data);
    const brandedLogo = logoWithPlan(response.data?.data);
    const brandedWebsiteLink = candidatePortalLogo(response.data?.data)
    yield put(
      ACTIONS.candidateCompanyRecieve({
        ...response.data.data,
        ...brandedSite,
        ...brandedLogo,
        ...brandedWebsiteLink
      })
    );
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* ProfileSagasWatch() {
  yield takeLatest(TYPE.GET_PROFILE_REQUEST, getUserProfile);
  yield takeLatest(TYPE.UPLOAD_CV_REQUEST, uploadCv);
  yield takeLatest(TYPE.UPLOAD_IMAGE_REQUEST, uploadImage);
  yield takeLatest(TYPE.SAVE_EDUCATION_INFO_REQUEST, saveEducationInfoRequest);
  yield takeLatest(TYPE.SAVE_CV_INFO_REQUEST, saveCVInfoRequest);

  yield takeLatest(
    TYPE.DELETE_EDUCATION_INFO_REQUEST,
    deleteEducationInfoRequest
  );
  yield takeLatest(
    TYPE.SAVE_WORK_EXPERIENCE_INFO_REQUEST,
    saveWorkExperienceInfoRequest
  );
  yield takeLatest(
    TYPE.DELETE_WORK_EXPERIENCE_INFO_REQUEST,
    deleteWorkExperienceInfoRequest
  );
  yield takeLatest(TYPE.EDIT_PERSONAL_INFO_REQUEST, editPersonalInfoRequest);
  yield takeLatest(TYPE.SAVE_SKILLS_REQUEST, saveSkillsInfoRequest);
  yield takeLatest(TYPE.SAVE_SIMPLE_PROFILE_REQUEST, saveSimpleProfile);
  yield takeLatest(
    TYPE.ADD_ADVERT_QUESTIONS_REQUEST,
    saveAdvertQuestionsRequest
  );
  yield takeLatest(
    TYPE.DISABLE_ADVERT_QUESTIONS_REQUEST,
    disabelAdvertQuestions
  );
  yield takeLatest(TYPE.SAVE_LICENSES_CERTIFICATES, saveCertificatesLicenses);
  yield takeLatest(
    TYPE.DELETE_LICENSES_CERTIFICATES_REQUEST,
    deleteCertificatesLicensesRequest
  );
  yield takeLatest(TYPE.CANDIDATE_COMPANY_REQUEST, CandidateCompanyRequest);
}
