import { axiosInstance } from "../../network/apis/index";
let handlerEnabled = true;

const salaryGradesLookup = async () => {
  return await axiosInstance.get(`/admin/salary_grade/Lookup/get`);
};
const residenciesLookup = async () => {
  return await axiosInstance.get(`/lookup/residency/get`);
};
const nationalitiesLookup = async () => {
  return await axiosInstance.get(`/lookup/nationality/get`);
};
const languagesLookup = async () => {
  return await axiosInstance.get(`/lookup/language/get`);
};
const careerLevelsLookup = async () => {
  return await axiosInstance.get(`/lookup/career_level/get`);
};
const jobtitlesLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/lookup`);
};
const collegesLookup = async () => {
  return await axiosInstance.get(`/lookup/college/get`);
};
const degreesLookup = async () => {
  return await axiosInstance.get(`/lookup/degree/get`);
};
const competenciesLookup = async () => {
  return await axiosInstance.get(`/lookup/competencies/get`);
};

const jobTitleLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/lookup`);
};

const salaryGradeFilterLookup = async (job_title_id) => {
  return await axiosInstance.get(`/admin/salary_grade/lookup/${job_title_id}`);
};

const companyBranchesLookup = async () => {
  return await axiosInstance.get(`/branch/lookup`);
};

const currencyLookup = async () => {
  return await axiosInstance.get(`/lookup/currency/get`);
};

const jobTitlesCategoryLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/categories/lookup`);
};

const agentsLookup = async () => {
  return await axiosInstance.get(`/admin/agent/lookups`);
};

const companyFieldsLookup = async () => {
  return await axiosInstance.get(`/admin/job_title/categories/lookup`);
};

const jobOpeningsLookup = async () => {
  return await axiosInstance.get(`/admin/job_request/lookup`);
};

const pipelineFlowsLookup = async () => {
  return await axiosInstance.get(
    `/company/pipelineSetup/get_piplineSetupFlowsWithoutPaging`
  );
};

const rejectionReasonsLookup = async () => {
  return await axiosInstance.get(`lookup/rejection_reasons`);
};

const companySizesLookup = async () => {
  return await axiosInstance.get(`/admin/lookups/company_sizes`);
};

const companyTypesLookup = async () => {
  return await axiosInstance.get(`/admin/lookups/company_types`);
};

const getCountriesLookup = async () => {
  return await axiosInstance.get(`/lookup/country/get`);
};
const getCitiesLookup = async (id) => {
  return await axiosInstance.get(`/lookup/city/get/${id}`);
};

const getAllJobRolesLookup = async () => {
  return await axiosInstance.get(`/admin/job_role/lookup`);
};

const getHearAboutUsList = async () => {
  return await axiosInstance.get(`/admin/lookups/hear_about_us`);
};
const getContractType = async () => {
  return await axiosInstance.get(`/candidate_job_request/contract_type`);
};
const collegeLookupRequest = async () => {
  return await axiosInstance.get("/lookup/college/get", { handlerEnabled });
};
const degreeLookupRequest = async () => {
  return await axiosInstance.get("/lookup/degree/get", { handlerEnabled });
};
const jobTitleLookupRequest = async () => {
  return await axiosInstance.get("/admin/job_title/lookup", { handlerEnabled });
};
const jobTitleFilterCategoryLookupRequest = async (category_id) => {
  const params = { category_id };
  return await axiosInstance.get(`/admin/job_title/lookup/category`, {
    params,
    handlerEnabled,
  });
};
const jobTitleCategoryLookupRequest = async () => {
  return await axiosInstance.get("/admin/job_title/categories/lookup", {
    handlerEnabled,
  });
};

const residenciesLookupRequest = async () => {
  return await axiosInstance.get(`/lookup/residency/get`, { handlerEnabled });
};

const languagesWithOutEnglish = async () => {
  return await axiosInstance.get(`/lookup/language/get`, {
    handlerEnabled,
  });
};

const jobCategoryLookupRequest = async () => {
  return await axiosInstance.get(`/candidate_job_request/job_category`, {
    handlerEnabled,
  });
};

const jobContractTypeLookupRequest = async () => {
  return await axiosInstance.get(`/candidate_job_request/contract_type`, {
    handlerEnabled,
  });
};

const countriesLookupsRequest = async () => {
  return await axiosInstance.get(`/lookup/country/get`, { handlerEnabled });
};

const citiesLookupsRequest = async (countryId) => {
  return await axiosInstance.get(`/lookup/city/get/${countryId}`, {
    handlerEnabled,
  });
};

const skillsLookupsRequest = async () => {
  return await axiosInstance.get(`/lookup/competencies/get`, {
    handlerEnabled,
  });
};

const currencyLookupRequest = async () => {
  return await axiosInstance.get(`/lookup/currency/get`, { handlerEnabled });
};

const getUserNationality = async () =>
  await axiosInstance.get(`/lookup/nationality/get`, {
    handlerEnabled,
  });
const jobTypesLookupRequest = async () =>
  await axiosInstance.get(`/candidate/job_preferences/all_job_titles`, {
    handlerEnabled,
  });

const countriesWithCitiesLookupsRequest = async (country_ids) => {
  const params = { country_ids };
  return await axiosInstance.get(`/candidate/job_preferences/countries_with_cities`, {
    handlerEnabled,
    params
  });
};

export default {
  getCountriesLookup,
  getCitiesLookup,
  getAllJobRolesLookup,
  salaryGradesLookup,
  residenciesLookup,
  nationalitiesLookup,
  languagesLookup,
  careerLevelsLookup,
  jobtitlesLookup,
  collegesLookup,
  degreesLookup,
  competenciesLookup,
  jobTitleLookup,
  salaryGradeFilterLookup,
  companyBranchesLookup,
  currencyLookup,
  jobTitlesCategoryLookup,
  agentsLookup,
  companyFieldsLookup,
  jobOpeningsLookup,
  pipelineFlowsLookup,
  rejectionReasonsLookup,
  companySizesLookup,
  companyTypesLookup,
  getHearAboutUsList,
  getContractType,
  collegeLookupRequest,
  degreeLookupRequest,
  jobTitleLookupRequest,
  jobTitleCategoryLookupRequest,
  jobTitleFilterCategoryLookupRequest,
  residenciesLookupRequest,
  languagesWithOutEnglish,
  jobCategoryLookupRequest,
  jobContractTypeLookupRequest,
  countriesLookupsRequest,
  citiesLookupsRequest,
  skillsLookupsRequest,
  currencyLookupRequest,
  getUserNationality,
  jobTypesLookupRequest,
  countriesWithCitiesLookupsRequest
};
