import { axiosInstance } from "../../network/apis/index";

export const getJobs = async (params) => {
  return await axiosInstance.get("/candidate_job_request/all_jobs", {
    params,
  });
};

export const getJobDetails = async (id) => {
  return await axiosInstance.get(`/candidate_job_request/${id}`);
};

export const addRemoveFavoriteJobs = async (id) => {
  return await axiosInstance.put(
    `/candidate/companies/${id}/favoriteAndUnfavorite`
  );
};

export const shareJobRequest = async (id) => {
  return await axiosInstance.post(
    ``
  );
};

export const getMyJobsApplied = async (params) => {
  return await axiosInstance.get(`/candidate_job_request/myJobs/applied`, {
    params,
  });
};

export const getLatestJobs = async (params) => {
  return await axiosInstance.get(`/candidate/home/jobs`, {
    params,
  });
};
export const getMyJobsSaved = async (params) => {
  return await axiosInstance.get(`/candidate_job_request/myJobs/favorite`, {
    params,
  });
};
