import axios from "axios";
import { requestHandler, successHandler, errorHandler } from "../interceptors";

//add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
  
});

export const axiosInstance2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    'Access-Control-Allow-Origin': '*',
    'Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials':true
  },
  mode: 'no-cors',
});

// Handle request process
axiosInstance.interceptors.request.use(request => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
