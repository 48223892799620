import { call, put } from "redux-saga/effects";
import API from "./api";
import COMPANYAPI from "./../AllCompanies/apis";
import * as ACTIONS from "./actions";
import * as COMPANYACTIONS from "./../AllCompanies/actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./types";

export function* followedCompaniesRequest({payload , param}) {
  try {
    const response = yield call(API.followedCompanies,payload);
    yield put(ACTIONS.followedCompaniesReceive(response.data));
    const companyResponse = yield call(COMPANYAPI.getAllCompanies,param);
    yield put(COMPANYACTIONS.allCompaniesReceive(companyResponse.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}


export function* followedCompanies() {
  yield takeLatest(TYPES.FOLLOWED_COMPANIES_REQUEST, followedCompaniesRequest);
}
