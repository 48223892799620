import React from "react";
import { Router } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import history from "../routes/History";
import Routes from "../routes/Routes";
import messages from "../assets/Local/messages";
import Loader from "../components/Loader/Loader";
import { MaterialSnackbar } from "../components/Snackbar/Snackbar";
import ScrollToTop from "react-scroll-to-top";
import "./App.scss";
import { getURLParams } from "../utils/Shared";
import { setCurrentLang } from "../store/Lang/action";
import { candidateCompanyRequest } from "../store/Profile/actions";
import { domainOfCandidatePortal } from "../utils/Shared";
import { popupNotificationRequest } from "../store/Notification/actions"
import { realTimeDbUnreadNotifications } from "../firebase/utlis";
import ReactGA from "react-ga4";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      pathname: "/",
    };
  }

  componentDidMount() {
    console.log(process.env);
    const trackingId = process.env.REACT_APP_COMPANY_GOOGLE_ANALYTICS_KEY_GA4;

    // ReactGA.initialize(trackingId);
    const locale = getURLParams("locale");
    if (locale && (locale === "ar" || locale === "en"))
      this.props.setCurrentLang(locale);
    localStorage.getItem("lang")
      ? localStorage.setItem("lang", localStorage.getItem("lang"))
      : localStorage.setItem("lang", locale || this.props.lang);

    const domain = domainOfCandidatePortal();
    if (domain && window.location.href.includes("auth")) {
      this.props.candidateCompanyRequest(domain);
    }
    if (this.props.is_authenticated) {
      this.props.popupNotificationRequest()
      const decodedToken = jwt_decode(JSON.parse(Cookies.get("userData")).token);
      realTimeDbUnreadNotifications(decodedToken.sub)
    }
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "yzspq3ie"
    });
    window.Intercom("update");
  }
  changeThemeColor = (primary_color, secondary_color) => {
    primary_color &&
      document.documentElement.style.setProperty(
        "--primary-color",
        primary_color
      );
    secondary_color &&
      document.documentElement.style.setProperty(
        "--secondary-color",
        secondary_color
      );
  };

  componentDidUpdate() {
    if (this.props.candidateCompany.done) {
      const { primary_color, secondary_color } = this.props.candidateCompany;
      this.changeThemeColor(primary_color, secondary_color);
    }
  }

  render() {
    const { lang, loading } = this.props;
    const scrollStyle = {
      left: "40px",
      right: "auto",
      bottom: "25px"
    };
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div
          className={lang === "ar" ? "rtl" : "ltr"}
          dir={lang === "ar" ? "rtl" : "ltr"}
        >
          {loading ? <Loader /> : null}
          <Router history={history}>
            <MaterialSnackbar />
            <ScrollToTop smooth top={400} style={scrollStyle} />
            {Routes}
          </Router>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ lang, loading, profile, auth }) => ({
  lang,
  loading,
  candidateCompany: profile.candidateCompany,
  is_authenticated: auth.is_authenticated
});

export default connect(mapStateToProps, {
  setCurrentLang,
  candidateCompanyRequest,
  popupNotificationRequest
})(App);
