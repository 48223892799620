
import * as types from "./types";

export const popupNotificationRequest = () => {
  return {
    type: types.POPUP_NOTIIFCATION_REQUEST,
  };
};

export const popupNotificationResponse=(payload)=>{
  return{
    type:types.POPUP_NOTIFICATION_RESPONSE,
    payload
  }

}

export const setNotificationVistedRequest=(payload)=>{
  return{
    type:types.SET_NOTIFICATION_VISITED_REQUEST,
    payload
  }
}


export const notificationListRequest=(payload)=>{
  return{
    type:types.NOTIFICATION_LIST_REQUEST,
    payload
  }
}

export const notificationListResponse=(payload)=>{
  return{
    type:types.NOTIFICATION_LIST_RESPONE,
    payload
  }
}

export const setNotificationSeenRequest = () => {
  return {
    type: types.SET_NOTIFICATION_SEEN,
  };
};


