import {axiosInstance} from '../../network/apis/index';

const getAllCompanies = async (params) => {
    return await axiosInstance.get(`candidate/companies/list` , {
        params
      });
}

export default {
    getAllCompanies
};