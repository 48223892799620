import * as types from "./types";


export const jobPreferenceRequest = payload => ({
  type: types.JOB_PREFERENCE_REQUSET,
  payload
})

export const jobPreferenceReceive = payload => ({
  type: types.JOB_PREFERENCE_RECIEVE,
  payload
})

export const deletejobPreferenceRequest = (payload, activePage) => ({
  type: types.DELETE_JOB_PREFERENCE_REQUSET,
  payload,
  activePage
});

export const addjobPreferenceRequest = (payload) => {
  return {
    type: types.ADD_JOB_PREFERENCE_REQUEST,
    payload,
  };
};

export const addjobPreferenceByJobRequest = (payload) => {
  return {
    type: types.ADD_JOB_PREFERENCE_BY_JOB_REQUEST,
    payload,
  };
};

export const addjobPreferenceRecieve = (payload) => {
  return {
    type: types.ADD_JOB_PREFERENCE_RECIEVE,
    payload,
  };
};



export const getJobPreferenceRequest = (payload) => {
  return {
    type: types.GET_JOB_PREFERENCE_REQUEST,
    payload,
  };
};
export const getJobPreferenceRecieve = (payload) => {
  return {
    type: types.GET_JOB_PREFERENCE_RECIEVE,
    payload,
  };
};


export const editJobPreferenceRequest = (payload) => {
  return {
    type: types.EDIT_JOB_PREFERENCE_REQUEST,
    payload,
  };
};
export const editJobPreferenceRecieve = (payload) => {
  return {
    type: types.EDIT_JOB_PREFERENCE_RECIEVE,
    payload,
  };
};