import * as types from "./types";

const INITIAL_STATE = {
  jobsList: {},
  jobDetails: {
    job_requisition: {},
  },
  jobFilters: null,
  myJobs: {},
  latestJobs: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_JOBS_RECEIVE:
      return {
        ...state,
        jobsList: { ...action.payload },
      };
    case types.GET_JOB_DETAILS_RECEIVE:
      return {
        ...state,
        jobDetails: { ...action.payload },
      };
    case types.ADD_JOB_FILTERS_REQUEST:
      return {
        ...state,
        jobFilters: { ...action.payload },
      };
      case types.SHARE_JOB_RECIEVE:
      return {
        ...state,
        shareJob: action.payload ,
      };
    case types.GET_LATEST_JOBS_RECIEVE:
      return {
        ...state,
        latestJobs: action.payload,
      };

    case types.GET_MY_JOBS_SAVED_RECEIVE:
    case types.GET_MY_JOBS_APPLIED_RECEIVE:
      return {
        ...state,
        myJobs: { ...action.payload },
      };
    default:
      return state;
  }
};
