import { call, put } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import * as API from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import { dispatchSnackbarError } from "../../utils/Shared";

export function* allIndustriesSaga({payload}) {
  try {
    const response = yield call(API.getAllIndustries,payload);
    yield put(ACTIONS.allIndustriesReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}


export function* industriesSagas() {
  yield takeLatest(TYPES.GET_ALL_INDUSTRIES_REQUEST, allIndustriesSaga);
}
