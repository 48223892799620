import store from "../store";
import moment from "moment-timezone";
import { showSnackbarAction } from "../store/Snackbar/SnackbarAction";
import messages from "../assets/Local/messages";
import HRCOMLogoNavbar from "../assets/images/0-01.png";
import HRCOMLogoWhite from "../assets/images/ic_logo-footer.svg";
import HRCOMLogoAuth from "../assets/images/0-06.png";
import HRCOMLogoNoWord from "../assets/images/logo-no-word.png";
import LOGO_HOME_HEADER from "../assets/images/img_hero.png";
import History from "../routes/History";
const queryString = require("query-string");

export function dispatchSnackbarError(data) {
  if (data) {
    const errorMsg = data.error?.message;
    store.dispatch(showSnackbarAction(errorMsg, "error"));
  }
}
export function dispatchSnackbarSuccess(message) {
  const lang = store.getState().lang;
  store.dispatch(
    showSnackbarAction(messages[lang].snackbar[message], "success")
  );
}

export const getChangedFields = (values) => {
  let cloneValues = { ...values };
  Object.keys(values).forEach((key) => {
    if (
      values[key] === false ||
      (typeof values[key] === "string" && values[key].trim() === "")
    ) {
      delete cloneValues[key];
    }
  });
  return cloneValues;
};
export function getDomain() {
  return "";
}
export const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export function getURLParams(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}

export const getEnv = () => {
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return "hrcom.ibtikar.sa";
  } else if (window.location.href.includes("stage.hrcom.site")) {
    return "stage.hrcom.site";
  } else if (window.location.href.includes("hrcom.site")) {
    return "hrcom.site";
  } else if (window.location.href.includes("hrcom.io")) {
    return `hrcom.io`;
  } else if (window.location.href.includes("hrcom.co")) {
    return `hrcom.co`;
  }else {
    return "localhost";
  }
};

export const getAdminPortal = () => {
  const lang = store.getState().lang;
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return `https://admin-stage-hrcom.ibtikar.sa?locale=${lang}`;
  } else if (window.location.href.includes("stage.hrcom.site")) {
    return `https://admin-stage.hrcom.site?locale=${lang}`;
  } else if (window.location.href.includes("hrcom.site")) {
    return `https://admin.hrcom.site?locale=${lang}`;
  } else if (window.location.href.includes("hrcom.io")) {
    return `https://hrcom.io?locale=${lang}`;
  }else if (window.location.href.includes("hrcom.co")) {
    return `https://hrcom.co?locale=${lang}`;
  }
};
export const authRedirection = (domain) => {
  if (!domain) {
    window.location.href = `/`;
  } else {
    window.location.href = getCandidatePortalHome(domain);
  }
};
export const getCandidatePortalHome = (domain) => {
  if (window.location.href.includes("hrcom.ibtikar.sa")) {
    return `https://${domain}-candidate-stage.hrcom.ibtikar.sa`;
  } else if (window.location.href.includes("stage.hrcom.site")) {
    return `https://${domain}-candidate.stage.hrcom.site`;
  } else if (window.location.href.includes("hrcom.site")) {
    return `https://${domain}.candidate.hrcom.site`;
  } else if (window.location.href.includes("hrcom.io")) {
    return `https://${domain}.candidate.hrcom.io`;
  }else if (window.location.href.includes("hrcom.co")) {
    return `https://${domain}.candidates.hrcom.co`;
  }
};
export const getAutoCompleteFields = (arr, id) => {
  return (
    arr.filter((item) => item.id === id)[0] || {
      id: "",
      name_en: "",
      name_ar: "",
    }
  );
};

export const colorsWithPlan = ({
  primary_color,
  secondary_color,
  candidate_home_image,
  plan_features: { has_brand_colors },
  company_subscription_details: { current_plan_end_date },
}) => {
  if (expireAfter3Days(current_plan_end_date)) {
    return {
      ...defaultSetupColors_Logos.colors,
      candidate_home_image: LOGO_HOME_HEADER,
    };
  } else if (has_brand_colors) {
    return {
      primary_color: primary_color ? primary_color : "#86364e",
      secondary_color: secondary_color ? secondary_color : "#ea8685",
      candidate_home_image: candidate_home_image,
    };
  } else if (!has_brand_colors) {
    return {
      ...defaultSetupColors_Logos.colors,
      candidate_home_image: LOGO_HOME_HEADER,
    };
  }
};

export const logoWithPlan = ({
  company_logo_url,
  secondary_color,
  primary_color,
  company_subscription_details,
  plan_features: { has_brand_logo, has_brand_colors },
}) => {
  const expire = company_subscription_details.current_plan_end_date;
  if (expireAfter3Days(expire)) {
    return defaultSetupColors_Logos.logos;
  }
  if (has_brand_logo) {
    return {
      logo_navbar: company_logo_url,
      logo_auth: company_logo_url,
    };
  }
  if (
    has_brand_colors &&
    (primary_color !== "#86364e" || secondary_color !== "#ea8685")
  ) {
    return {
      logo_navbar: HRCOMLogoNoWord,
      logo_auth: HRCOMLogoWhite,
    };
  }
  if (primary_color === "#86364e" && secondary_color === "#ea8685") {
    return defaultSetupColors_Logos.logos;
  }
};

export const expireAfter3Days = (date) => {
  const endDate = new Date(date);
  endDate.setDate(endDate.getDate() + 3);
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  return date === null ? false : today > endDate;
};

export const defaultSetupColors_Logos = {
  colors: {
    primary_color: "#86364e",
    secondary_color: "#ea8685",
  },
  logos: {
    logo_navbar: HRCOMLogoNavbar,
    logo_auth: HRCOMLogoAuth,
  },
};

export const candidatePortalLogo = (props) => {
  const {
    website_url,
    plan_features: { has_linking_your_website },
  } = props;
  const expire = props.company_subscription_details.current_plan_end_date;
  let disableLink = "";
  let url = "/";
  if (has_linking_your_website) {
    url = website_url && website_url !== "" ? `${website_url}` : url;
  }
  if (expireAfter3Days(expire)) {
    url = "/";
  }
  return {
    url,
    disableLink,
  };
};

export const domainOfCandidatePortal = () => {
  const param = queryString.parse(window.location.search);
  return param.domain || null;
};

export const updateURLocalization = (l) => {
  const locale = getURLParams("locale");
  if (locale) {
    History.push({
      pathname: History.location.pathname,
      search: `?locale=${l}`,
    });
  }
};

export const blobToBase64 = async (blob, fn) => {
  return new Promise((resolve, onerror) => {
    //represent the  blob image as a file,
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (event) {
      resolve(event.target.result);
    };
  });
};

export  const isToday = (pastDate) => {
  const formatedGiven = moment.utc(pastDate).local().format("YYYY-MM-DD");
  const given = moment(formatedGiven, "YYYY-MM-DD");
  const current = moment().startOf("day");
  return moment.duration(given.diff(current)).asDays() === 0 ? true : false;
};