import * as types from "./types";

export const allIndustriesRequest = (payload) => ({
  type: types.GET_ALL_INDUSTRIES_REQUEST,
  payload,
});

export const allIndustriesReceive = (payload) => ({
  type: types.GET_ALL_INDUSTRIES_RECEIVE,
  payload,
});
