import * as types from "./types";

export const followedCompaniesRequest = (payload ,param) => ({
  type: types.FOLLOWED_COMPANIES_REQUEST,
  payload,
  param
});

export const followedCompaniesReceive = (payload ,param )=> ({
  type: types.FOLLOWED_COMPANIES_RECEIVE,
  payload,
  param
});
