import { call, put, takeLatest } from "redux-saga/effects";
import API from "./apis";
import * as ACTION from "./actions";
import * as TYPE from "./types";
import History from "./../../routes/History";
import ReactGA from "react-ga";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";

export function* jobScreeningQuestions({ payload }) {
  try {
    const response = yield call(API.jobScreeningQuestions, payload);
    yield put(ACTION.getScreeningQuestionReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* applyJobRequest({ payload }) {
  try {
    const response = yield call(API.applyJobRequest, payload);
    yield put(ACTION.applyJobReceive(response.data.data));
    dispatchSnackbarSuccess("successApply");
    History.push(`/jobs`);
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Apply to jobs `,
      action: "User pressed apply to jobs button",
    });
  } catch (err) {
    if (err.response.data.error.code === 2) {
      History.push(`/screeningQuestions/${payload.job_request_id}`);
    } else if (err.response.data.error.code === 1) {
      History.push({
        pathname: "/profile",
        state: {
          job_request_id: payload.job_request_id,
          showProfileErrMsg: true,
        },
      });
    } else {
      dispatchSnackbarError(err.response?.data);
    }
  }
}

export function* ScreeningQuestionsSagasWatch() {
  yield takeLatest(
    TYPE.GET_JOB_SCREENING_QUESTIONS_REQUEST,
    jobScreeningQuestions
  );
  yield takeLatest(TYPE.APPLY_JOB_REQUEST, applyJobRequest);
}
